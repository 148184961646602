import axios from "@/axios.js";

export default {
  async fetchFleetTypes({ commit }) {
    try {
      const { data } = await axios.get(`/shuttle/fleet-type`);
      commit("setFleetTypes", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFleetType({ commit }, params) {
    try {
      const { data } = await axios.get(`/shuttle/fleet-type/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeFleetType({ commit }, params) {
    try {
      const { data } = await axios.post(`/shuttle/fleet-type`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateFleetType({ commit }, params) {
    try {
      const { data } = await axios.post(`/shuttle/fleet-type/${params.id}`, params.body);
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeFleetType({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/shuttle/fleet-type/${params}`);
      dispatch("fetchFleetTypes");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
