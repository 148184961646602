<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Bus Product</h4>
    </vs-row>

    <form @submit.prevent="storeData">
      <vs-row vs-w="12">
        <vs-col vs-w="12" class="mb-3">
          <span class="mb-2 block text-sm">Brand (*)</span>
          <v-select
            label="name"
            :options="brands"
            :reduce="(type) => type.id"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="payload.brand_id"
          />
          <span class="block text-sm mt-1 text-danger">{{
            errors.first("brand_id")
          }}</span>
        </vs-col>

        <vs-col vs-w="12" class="mb-3">
          <span class="mb-2 block text-sm">Product (*)</span>
          <v-select
            label="name"
            :options="products"
            :reduce="(type) => type.id"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="payload.product_id"
          />
          <span class="block text-sm mt-1 text-danger">{{
            errors.first("product_id")
          }}</span>
        </vs-col>

        <vs-col vs-w="12" class="mb-3">
          <span class="mb-2 block text-sm">Fleet Type (*)</span>
          <v-select
            label="name"
            :options="fleetTypes"
            :reduce="(type) => type.id"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="payload.fleet_type_id"
          />
          <span class="block text-sm mt-1 text-danger">{{
            errors.first("fleet_type_id")
          }}</span>
        </vs-col>

        <vs-col vs-w="12" class="mb-3">
          <span class="mb-2 block text-sm">Seat Arrangement (*)</span>
          <v-select
            label="name"
            :options="seats"
            :reduce="(type) => type.id"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="payload.seat_arrangement_id"
          />
          <span class="block text-sm mt-1 text-danger">{{
            errors.first("fleet_type_id")
          }}</span>
        </vs-col>

        <vs-col vs-w="12" class="mb-3">
          <vs-input class="w-full" label="Name (*)" v-model="payload.name" />
          <span class="block text-sm mt-1 text-danger">{{
            errors.first("name")
          }}</span>
        </vs-col>
        <vs-col vs-w="12" class="mb-3">
          <vs-input
            class="w-full"
            label="Total Seat (*)"
            v-model="payload.total_seat"
          />
          <span class="block text-sm mt-1 text-danger">{{
            errors.first("total_seat")
          }}</span>
        </vs-col>

        <vs-col vs-w="12" class="mb-3">
          <p class="mb-0" style="font-size: 12px; color: #000000b3">
            Description (*)
          </p>
          <vs-textarea class="w-full" v-model="payload.description" />
          <span class="block text-sm text-danger">{{
            errors.first("description")
          }}</span>
        </vs-col>

        <vs-col vs-w="12" class="mb-3">
          <p><b>Choose Feature</b></p>
        </vs-col>
        <vs-col
          v-for="(feature, key) in features"
          :key="key"
          vs-w="6"
          class="mb-1"
        >
          <vs-checkbox @change="toggleFeature($event, feature)" class="mb-3">{{
            feature.name
          }}</vs-checkbox>
        </vs-col>

        <vs-col vs-w="12" class="mt-3">
          <div class="vx-col w-full">
            <button
              type="submit"
              class="vs-component vs-button vs-button-primary vs-button-filled mr-3 mb-2"
            >
              Submit
            </button>
          </div>
        </vs-col>
      </vs-row>
    </form>
  </vx-card>
</template>

<script>
// Store Module

import busProductStore from "@/store/bus-product";
import brandStore from "@/store/brand-management/moduleBrandManagement.js";
import featureStore from "@/store/bus-feature";
import moduleProductManagement from "@/store/product-management/moduleProductManagement.js";
import fleetTypeStore from "@/store/fleet-type";
import seatStore from "@/store/seat";

import vSelect from "vue-select";

export default {
  metaInfo: {
    title: "Add Bus Product",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  components: {
    vSelect,
  },

  data: () => ({
    payload: {
      name: "",
      brand_id: "",
      product_id: "",
      fleet_type_id: "",
      seat_arrangement_id: "",
      total_seat: 0,
      description: "",
      features: [],
    },
  }),

  computed: {
    brands() {
      return this.$store.state.brand.brands || [];
    },
    products() {
      return this.$store.state.product.products || [];
    },
    fleetTypes() {
      return this.$store.state.fleetType.fleetTypes || [];
    },
    seats() {
      return this.$store.state.seat.seats || [];
    },
    features() {
      return this.$store.state.feature.busFeatures || [];
    },
  },

  methods: {
    async storeData() {
      const formData = new FormData();
      formData.set("name", this.payload.name);
      formData.set("brand_id", this.payload.brand_id);
      formData.set("product_id", this.payload.product_id);
      formData.set("fleet_type_id", this.payload.fleet_type_id);
      formData.set("seat_arrangement_id", this.payload.seat_arrangement_id);
      formData.set("total_seat", this.payload.total_seat);
      formData.set("description", this.payload.description);
      this.payload.features.map((feature) => {
        formData.set("bus_features[]", feature);
      });

      try {
        const response = await this.$store.dispatch(
          "busProduct/storeBusProduct",
          formData
        );
        if (!response.success) {
          throw response;
        }
        this.$vs.notify({
          title: "Successful",
          text: response.msg_status,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
        });

        this.$router.push("/bus-product");
      } catch (error) {
        console.log(error);
        this.$catchErrorResponse(error);
      }
    },
    toggleFeature(event, feature) {
      if (event.target.checked) {
        this.payload.features.push(feature.id);
      } else {
        this.payload.features = this.payload.features.filter(
          (p) => p != feature.id
        );
      }
    },
  },

  async created() {
    if (!busProductStore.isRegistered) {
      this.$store.registerModule("busProduct", busProductStore);
      busProductStore.isRegistered = true;
    }
    if (!brandStore.isRegistered) {
      this.$store.registerModule("brand", brandStore);
      brandStore.isRegistered = true;
    }
    if (!moduleProductManagement.isRegistered) {
      this.$store.registerModule("product", moduleProductManagement);
      moduleProductManagement.isRegistered = true;
    }
    if (!fleetTypeStore.isRegistered) {
      this.$store.registerModule("fleetType", fleetTypeStore);
      fleetTypeStore.isRegistered = true;
    }
    if (!seatStore.isRegistered) {
      this.$store.registerModule("seat", seatStore);
      seatStore.isRegistered = true;
    }
    if (!featureStore.isRegistered) {
      this.$store.registerModule("feature", featureStore);
      featureStore.isRegistered = true;
    }

    Promise.all([
      await this.$store.dispatch("brand/fetchBrands"),
      await this.$store.dispatch("product/fetchProducts"),
      await this.$store.dispatch("fleetType/fetchFleetTypes"),
      await this.$store.dispatch("feature/fetchBusFeatures"),
      await this.$store.dispatch("seat/fetchSeats"),
    ]);
  },
};
</script>

<style></style>
